import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
  Container,
} from "@mui/material";
import "./Services.css"; // Import the CSS file for styling
import { useNavigate, useParams } from "react-router-dom";

export const servicesData = [
  {
    id: "1",
    title: "Facility & Environmental Cleaning",
    description:
      "We will clean your facility, and sanitize it from viruses like COVID",
    imageUrl: require("../../images/cleaning_1.jpg"),
  },
  {
    id: "2",
    title: "Homes & Nursing Home Cleaning",
    description:
      "We provide cleaning services for hoems as well as nursing homes",
    imageUrl: require("../../images/cleaning_2.webp"),
  },
  {
    id: "3",
    title: "Education and Competency Training",
    description: "We provide Health training and education for individuals",
    imageUrl: require("../../images/training.webp"),
  },
  // Add more service data as needed
];

export const ServiceDetails = () => {
  const { id } = useParams();
  if (!id) return null;
  const service = servicesData.find((x) => x.id === id);
  if (!service) return null;
  return (
    <Container>
      <Box
        sx={{
          alignItems: "center",
          flexDirection: "column",
          display: "flex",
        }}
      >
        <Typography
          gutterBottom
          variant="h4"
          component="div"
          color="secondary"
          sx={{ textAlign: "center", mt: 5 }}
        >
          {service.title}
        </Typography>
        <Box
          sx={{
            width: {
              xs: "100%",
              md: "50%",
            },
            alignItems: "center",
            flexDirection: "column",
            display: "flex",
            m: 2,
          }}
        >
          <img
            src={service.imageUrl}
            alt={service.description}
            style={{ width: "100%" }}
          />
        </Box>
        <Typography sx={{ textAlign: "center", mb: 5 }}>
          {service.description}
        </Typography>
      </Box>
    </Container>
  );
};
const Services = () => {
  const navigate = useNavigate();
  return (
    <Box>
      <Typography
        gutterBottom
        variant="h4"
        component="div"
        color="secondary"
        sx={{ textAlign: "center", m: 5 }}
      >
        Our Services
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          margin: 1,
          flexWrap: "wrap",
        }}
      >
        {servicesData.map((service) => (
          <Card
            key={service.id}
            sx={{
              width: { xs: "100%", lg: "25%", md: "30%" },
              transition: "transform 0.3s",
              margin: 1,
            }}
          >
            <CardActionArea onClick={() => navigate(`/services/${service.id}`)}>
              <CardMedia
                component="img"
                alt={service.title}
                height="240"
                image={service.imageUrl}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {service.title}
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                  {service.description}
                </Typography>
                <Typography variant="button" color="primary" sx={{ mt: 5 }}>
                  LEARN MORE
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default Services;
