import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import {
  Email,
  Phone,
  Facebook,
  Twitter,
  Instagram,
} from "@mui/icons-material";
import "./Footer.css"; // Import the CSS file for styling

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <IconButton>
              <Email />
            </IconButton>
            <Typography variant="body2" color="textSecondary">
              <a href="mailto:info@banvego.com">info@banvego.com</a>
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <IconButton>
              <Phone />
            </IconButton>
            <Typography variant="body2" color="textSecondary">
              +1 (123) 456-7890
            </Typography>
          </Box>
        </div>
        <div className="footer-section">
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <IconButton>
              <Facebook />
            </IconButton>
            <IconButton>
              <Twitter />
            </IconButton>
            <IconButton>
              <Instagram />
            </IconButton>
          </Box>
        </div>
        <div className="footer-section">
          <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
            © 2024 Banvego Health Solutions. All rights reserved.
          </Typography>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
