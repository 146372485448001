import React from "react";
import "./HeroSection.css"; // Import the CSS file for styling
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const HeroSection = () => {
  const nav = useNavigate();
  return (
    <div className="hero-section">
      {/* Background Image */}
      <div className="hero-image"></div>

      {/* Text Overlay */}
      <div className="hero-text">
        <h1>Welcome to Banvego Health Solutions</h1>
        <p>
          Providing Evironmental Cleaning and Training Solutions to Central
          Florida{" "}
        </p>
        <p>
          <Button
            variant="contained"
            color="secondary"
            sx={{ mt: 2 }}
            onClick={() => nav("/schedule")}
          >
            Schedule Consultation
          </Button>
        </p>
      </div>
    </div>
  );
};

export default HeroSection;
