import { Box } from "@mui/system";
import { Button, Container, TextField, Typography } from "@mui/material";
import React from "react";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import EmailIcon from "@mui/icons-material/Email";
import HeroSection from "./HeroSection";
import Services from "../Services/Services";
import AboutUs from "../About/AboutUs";

const Home = () => {
  //swal alert

  return (
    <Box sx={{ p: 0, mb: 2, alignItems: "center" }}>
      <HeroSection />
      <Services />
      <Box sx={{ background: "#ddd" }}>
        <AboutUs />
      </Box>
    </Box>
  );
};

export default Home;
