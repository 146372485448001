import React from "react";
import { Container, Typography, Avatar, Box } from "@mui/material";
import "./AboutUs.css"; // Import the CSS file for styling

const AboutUs = () => {
  return (
    <Container className="about-us-container" sx={{ padding: 2 }}>
      <Box>
        {/* <Paper elevation={3} className="about-us-paper"> */}
        <Typography
          gutterBottom
          variant="h4"
          component="div"
          color="secondary"
          sx={{ textAlign: "center", m: 5 }}
        >
          About Us
        </Typography>
        <Typography variant="body1">
          Welcome to Banvego Health Solutions, your trusted partner for
          comprehensive health services
        </Typography>
        <Typography variant="body1">
          Our team is dedicated to providing top-notch house cleaning, nursing
          home cleaning, facility and environmental care, education, and
          competency training. We strive to ensure a healthy and clean
          environment for our clients.
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            m: 5,
          }}
        >
          <Avatar
            alt="CEO"
            src={require("../../images/veronica.webp")}
            sx={{ width: 100, height: 100 }}
          />
          <Typography variant="h6" gutterBottom sx={{ textAlign: "center" }}>
            Veronica Gbago <br />
            (CEO & Founder)
          </Typography>
        </Box>
      </Box>
      {/* </Paper> */}
    </Container>
  );
};

export default AboutUs;
